import { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'next-i18next';
import { phonemasks, Input, PhoneInput, ThemeContext, Select, Button } from '@forma/forma-ui-kit';
import ControlField from '@/components/fields/ControlField';
import DateTimeInput from '@/components/fields/DateTimeInput';
import ClientWrap from '@/components/ClientWrap';
import { queries } from '@/helpers/api';

import styles from '../request-demo.module.css';

const companySizes = [
  '1-10 сотрудников',
  '10-50 сотрудников',
  '50-100 сотрудников',
  '100-500 сотрудников',
];

const RequestDemoForm = ({ id = 'request_demo_form', onSubmit }) => {
  const { t } = useTranslation();
  const [phoneValidation, setPhoneValidation] = useState('');
  const [timeslots, setTimeslots] = useState(null);
  const { lang } = useContext(ThemeContext);

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    // defaultValues: {
    //   social: 'Звонок'
    // }
  });

  useEffect(() => {
    if (lang && phonemasks) {
      const code = lang === 'en' ? 'US' : lang.toUpperCase();
      const mask = phonemasks.find(({ iso }) => iso === code);
      if (mask) setPhoneValidation(mask.code + mask.mask);
    }
  }, [lang]);

  useEffect(() => {
    const getTimeslots = async () => {
      const res = await queries.getTimeslots();
      setTimeslots(res, lang);
    };
    getTimeslots();
  }, [lang]);

  const handleChangeMask = ({ mask, code }) => {
    setPhoneValidation(code + mask);
  };

  return (
    <form className={styles.fields} onSubmit={handleSubmit(onSubmit)}> {/* id for submit button */}
      <ControlField
        id="name"
        component={Input}
        viewStyle="secondary"
        name="name"
        placeholder={t('your_name')}
        control={control}
        {...register('name', {
          required: t('please_fill_the_field'),
          minLength: { value: 3, message: t('errors.min_lenght_3') },
          pattern: { value: /^[^0-9]+$/, message: t('errors.name_has_numbers') }
        })}
        meta={{
          error: errors.name,
          // errorMessage: errors.name?.message,
          containerClass: classNames(styles.inputContainer, styles.inputContainerName)
        }}
      />
      <ControlField
        id="phone"
        component={PhoneInput}
        viewStyle="secondary"
        masks={phonemasks}
        defaultMaskCode={lang === 'en' ? 'US' : lang.toUpperCase()}
        onChangeMask={handleChangeMask}
        name="phone"
        placeholder={t('phone_number')}
        control={control}
        error={errors.phone}
        // errorMessage={errors.phone?.message}
        {...register('phone', {
          required: t('please_fill_the_field'),
          minLength: { value: phoneValidation.length, message: t('errors.min_lenght_phone') }
        })}
        autoComplete="off"
        containerClass={classNames(styles.inputContainer, styles.inputContainerPhone)}
      />
      <ControlField
        id="email"
        component={Input}
        viewStyle="secondary"
        name="email"
        placeholder={t('email')}
        control={control}
        {...register('email', {
          required: t('please_fill_the_field'),
          minLength: { value: 3, message: t('errors.min_lenght_3') },
          pattern: { value: /\S+@\S+\.\S+/, message: t('errors.email_not_valid') }
        })}
        meta={{
          error: errors.email,
          // errorMessage: errors.email?.message,
          containerClass: styles.inputContainer
        }}
      />
      <ControlField
        id="date_time"
        component={DateTimeInput}
        viewStyle="secondary"
        name="date_time"
        placeholder={t('select_date_time_for_presentation')}
        control={control}
        {...register('date_time', {
          required: t('please_fill_the_field'),
          validate: {
            checkSlot: value => !!value.slot || t('errors.select_slot'),
          }
        })}
        timeslots={timeslots}
        meta={{
          error: errors.date_time,
          // errorMessage: errors.date_time?.message,
          containerClass: styles.inputContainer
        }}
      />
      <ControlField
        id="company_name"
        component={Input}
        viewStyle="secondary"
        name="company_name"
        placeholder={t('yours_company_name')}
        control={control}
        {...register('company_name', {
          required: t('please_fill_the_field'),
          minLength: { value: 3, message: t('errors.min_lenght_3') }
        })}
        meta={{
          error: errors.company_name,
          // errorMessage: errors.company_name?.message,
          containerClass: styles.inputContainer
        }}
      />
      <ClientWrap>
        <ControlField
          id="company_size"
          component={Select}
          viewStyle="secondary"
          name="company_size"
          placeholder={t('company_size')}
          options={companySizes.map((size) => ({ value: size, label: size }))}
          control={control}
          {...register('company_size', {
            required: t('please_fill_the_field'),
            minLength: { value: 3, message: t('errors.min_lenght_3') }
          })}
          meta={{
            error: errors.company_name,
            // errorMessage: errors.company_name?.message,
            containerClass: styles.inputContainer
          }}
        />
      </ClientWrap>
      {/* <ControlField
        id="social"
        component={SocialSelect}
        name="social"
        label={t('where_contact_you_presentation')}
        control={control}
        error={errors.social}
        errorMessage={errors.social?.message}
        {...register('social', { required: t('please_fill_the_field') })}
        containerClass={styles.inputContainer}
      /> */}

      <div className={styles.buttons}>
        <Button
          type="submit"
          viewStyle="primary"
          className={styles.button}
          disabled={!isValid}
          size="small"
          fullWidth
        >
          {t('request_demo')}
        </Button>
      </div>
      <div className={styles.notice}>
        <Trans
          i18nKey="user_agreement_form_notice"
          components={{ privacy: <Link href="/privacy" />, license: <Link href="/license" />, button: t('request_demo') }}
        />
      </div>
    </form>
  );
};

export default RequestDemoForm;
