import { useTranslation, Trans } from 'next-i18next';
import { Button, ButtonsContainer } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import { RequestDemo2Form } from '@/components/blocks/RequestDemo2';

import styles from '../request-demo-modal.module.css';

const ModalContent = ({
  onSubmit, onChangeValid, isFormValid, isSuccess, isError, onClose, subtitle, buttonText
}) => {
  const { t } = useTranslation();

  if (isSuccess) return (
    <div className={styles.modal}>
      <div className={styles.resultSubtitle}>{t('demonstration_time_notice')}</div>
      {/* <div className={styles.resultSubtitle}>{t('demonstration_description')}</div> */}
      <ButtonsContainer className={styles.buttons}>
        <Button
          className={styles.button}
          viewStyle="primary"
          size="medium"
          onClick={onClose}
        >
          {/* {t('uikit:done')} */}
          Хорошо
        </Button>
      </ButtonsContainer>
    </div>
  );

  if (isError) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modalSubtitle}>
        {subtitle}
      </div>
      <div className={styles.form}>
        <RequestDemo2Form id="request_demo2_form_modal" onSubmit={onSubmit} onChangeValid={onChangeValid} />
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          type="submit"
          form="request_demo2_form_modal"
          className={styles.button}
          viewStyle="primary"
          size="medium"
          disabled={!isFormValid}
        >
          {buttonText}
        </Button>
      </ButtonsContainer>
      <div className={styles.notice}>
        <Trans
          i18nKey="user_agreement_form_notice"
          components={{
            privacy: <Link href="/privacy" />,
            license: <Link href="/license" />,
            button: buttonText
          }}
        />
      </div>
    </div>
  );
};

export default ModalContent;
