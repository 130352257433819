import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import styles from '../request-demo.module.css';

const RequestDemoResult = ({ isSuccess, isError }) => {
  const { t } = useTranslation();

  if (isError) return (
    <div className={styles.result}>
      <div className={styles.resultTitle}>
        {t('contacts_form_send_error')}
      </div>
    </div>
  );

  return (
    <div className={styles.result}>
      <div className={styles.resultTitle}>
        Вы записались на демо Формы, в ближайшее время мы свяжемся с Вами.
      </div>
      <Image className={styles.resultImage} src="/images/demo-image-2.webp" width={417} height={305} alt="Форма - Демонстрация" />
    </div>
  );
};

export default RequestDemoResult;
