import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Button } from '@forma/forma-ui-kit';
import { analytics } from '@/helpers/analytics';

const RequestDemoButton = ({ children, className, viewStyle = 'primary', onClick, ...props }) => {
  const { t } = useTranslation();

  const handleClickButton = (e) => {
    const requestDemoBlock = document.getElementById('request_demo_block');
    if (requestDemoBlock) {
      e.preventDefault();
      requestDemoBlock.scrollIntoView({ behavior: 'smooth' });
    }
    analytics.clickTryTrial();
    if (onClick) onClick(e);
  };

  return (
    <Button
      {...props}
      as={Link}
      className={className}
      onClick={handleClickButton}
      viewStyle={viewStyle}
      href="/request"
    >
      {children ?? t('request_demo')}
    </Button>
  );
};

export default RequestDemoButton;
