import { useState, useContext } from 'react';
import { useCookie } from 'next-cookie';
import { Modal, ThemeContext } from '@forma/forma-ui-kit';
import ClientWrap from '@/components/ClientWrap';
import { analytics } from '@/helpers/analytics';
import { ModalTitle, ModalContent } from './elements';
import { useRequestDemo } from '@/components/blocks/RequestDemo';

export const RequestDemoModal = ({ open, onClose, title, successTitle, subtitle, buttonText }) => {
  const cookie = useCookie();
  const { lang } = useContext(ThemeContext);
  const [isFormValid, setFormValid] = useState(false);

  const {
    onSubmit,
    saveResult,
    isSuccess,
    isError,
    reset
  } = useRequestDemo({ lang, roistatVisit: cookie.get('roistat_visit') });

  const closeModal = () => {
    reset();
    onClose();
  };

  const handleSubmit = (values) => {
    onSubmit(values).then(success => {
      if (success) {
        analytics.sendRequestFullForm();
      }
    });;
  };

  return (
    <ClientWrap>
      <Modal
        open={open}
        onClose={closeModal}
        width="100%"
        maxWidth={714}
        title={
          <ModalTitle
            isSuccess={isSuccess}
            isError={isError}
            saveResult={saveResult}
            title={title}
            successTitle={successTitle}
          />
        }
      >
        <ModalContent
          onSubmit={handleSubmit}
          onChangeValid={setFormValid}
          isFormValid={isFormValid}
          isSuccess={isSuccess}
          isError={isError}
          onClose={closeModal}
          subtitle={subtitle}
          buttonText={buttonText}
        />
      </Modal>
    </ClientWrap>
  );
};

export default RequestDemoModal;
