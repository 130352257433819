import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { useCookie } from 'next-cookie';
import { ReactSVG } from 'react-svg';
import { ThemeContext } from '@forma/forma-ui-kit';
import PageBlock from '@/components/PageBlock';
import { analytics } from '@/helpers/analytics';
import { RequestDemoForm, RequestDemoResult } from './elements';
import { useRequestDemo } from './useRequestDemo';

import styles from './request-demo.module.css';

const list = [
  'Никаких обязательств по приобретению',
  'Обсудим вашу бизнес-задачу и процессы',
  'Получите помощь по внедрению документов',
  'Обсудим  возможности использования',
  'Ответим на все важные вопросы о платформе',
  'Перенесем до 3-х документов бесплатно',
  '7 дней пробного периода бесплатно',
];

const RequestDemo = () => {
  const cookie = useCookie();
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);

  const {
    onSubmit,
    saveResult,
    isSuccess,
    isError
  } = useRequestDemo({ lang, roistatVisit: cookie.get('roistat_visit') });

  const handleSubmit = (values) => {
    onSubmit(values).then(success => {
      if (success) {
        window.scrollTo({ top: 0, left: 0 });
        analytics.sendRequestFullForm();
      }
    });
  };

  return (
    <PageBlock className={styles.root} id="request_demo">
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            {/* <Trans
              i18nKey="sign_up_presentation"
              components={{ accent: <span className="accent-text" /> }}
            /> */}
            Запросите демонстрацию сервиса
          </h1>
          <p className={styles.subtitle}>
            {/* {t('sign_up_presentation_description')} */}
            Заполните поля в форме, чтобы оставить заявку на демонстрацию сервиса
            с экспертом в формате видео-встречи. Проанализируем ваши бизнес-процессы и покажем работу платформы за 13 минут.
          </p>
          <ul className={styles.list}>
            {list.map((item, index) => (
              <li className={styles.listItem} key={index}>
                <ReactSVG className={styles.listItemIcon} src="/icons/checkmark-circle.svg" wrapper="span" />
                {item}
              </li>
            ))}
          </ul>
        </div>
        {(isSuccess || isError) && (
          <RequestDemoResult isSuccess={isSuccess} isError={isError} />
        )}
        {!(isSuccess || isError) && (
          <div className={styles.form}>
            <RequestDemoForm onSubmit={handleSubmit} />
          </div>
        )}
      </div>
    </PageBlock>
  );
};

export default RequestDemo;
