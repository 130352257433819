import { useTranslation, Trans } from 'next-i18next';
import { format } from 'date-fns';
// import moment from 'moment';

const ModalTitle = ({ isSuccess, isError, saveResult, title, successTitle }) => {
  const { t } = useTranslation();

  if (isSuccess) return (
    <div>
      {successTitle}

      <span className="accent-text">
        {' '}{format(new Date(saveResult.date), 'dd.MM.yyyy')} {t('year_short')} {t('at')} {format(new Date(saveResult.date), 'HH:mm')}
        {/* {moment(saveResult.date).utcOffset(saveResult.utc).format('DD.MM.yyyy')} {t('year_short')} {t('at')} {moment(saveResult.date).utcOffset(saveResult.utc).format('HH:mm')} */}
      </span>
    </div>
  );

  if (isError) return (
    <Trans i18nKey="contacts_form_send_error" />
  );

  return title;
};

export default ModalTitle;
