import { useState, useContext } from 'react';
import { useCookie } from 'next-cookie';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import PageBlock from '@/components/PageBlock';
import { useRequestDemo } from '@/components/blocks/RequestDemo/useRequestDemo';
import { analytics } from '@/helpers/analytics';
import RequestDemo2Form from './RequestDemo2Form';

import styles from './request-demo-2.module.css';

const RequestDemo2 = ({ title, subtitle, buttonText, successTitle, image }) => {
  const cookie = useCookie();
  const { t } = useTranslation();
  const { viewport, lang } = useContext(ThemeContext);
  const [isFormValid, setFormValid] = useState(false);

  const isTablet = ['tablet', 'tabletS', 'phone'].includes(viewport);

  const {
    onSubmit,
    saveResult,
    isSuccess,
    isError
  } = useRequestDemo({ lang, roistatVisit: cookie.get('roistat_visit') });

  const handleSubmit = (values) => {
    onSubmit(values).then(success => {
      if (success) {
        // window.scrollTo({ top: 0, left: 0 });
        analytics.sendRequestFullForm();
      }
    });
  };

  if (isTablet) return (
    <PageBlock className={styles.root} id="request_demo_block">
      <div className={styles.content}>
        <div className={styles.left}>
          <h3 className={styles.title}>
            {title}
          </h3>
          {!(isSuccess || isError) && (
            <>
              <div className={styles.description}>
                {subtitle}
              </div>
              {image}
              <RequestDemo2Form onSubmit={handleSubmit} onChangeValid={setFormValid} />
              <div className={styles.buttons}>
                <Button
                  type="submit"
                  form="request_demo2_form"
                  className={styles.button}
                  viewStyle="primary"
                  size="medium"
                  disabled={!isFormValid}
                  fullWidth
                >
                  {buttonText}
                </Button>
              </div>
              <div className={styles.notice}>
                <Trans
                  i18nKey="user_agreement_form_notice"
                  components={{ privacy: <Link href="/privacy" />, license: <Link href="/license" />, button: buttonText }}
                />
              </div>
            </>
          )}
          {isSuccess && (
            <>
              {image}
              <div className={styles.resultTitle}>
                {successTitle}
                {' '}<span className="accent-text">
                  {format(new Date(saveResult.date), 'dd.MM.yyyy')} {t('year_short')} {t('at')} {format(new Date(saveResult.date), 'HH:mm')}
                </span>
              </div>
              <div className={styles.resultSubtitle}>{t('demonstration_time_notice')}</div>
              {/* <div className={styles.resultSubtitle}>{t('demonstration_description')}</div> */}
            </>
          )}
          {isError && (
            <>
              {image}
              <div className={styles.resultSubtitle}>{t('contacts_form_send_error')}</div>
            </>
          )}
        </div>
      </div>
    </PageBlock>
  );

  return (
    <PageBlock className={styles.root} id="request_demo_block">
      <div className={styles.content}>
        <div className={styles.left}>
          {!(isSuccess || isError) && (
            <>
              <h3 className={styles.title}>
                {title}
              </h3>
              <div className={styles.description}>
                {subtitle}
              </div>
              <RequestDemo2Form onSubmit={handleSubmit} onChangeValid={setFormValid} />
              <div className={styles.buttons}>
                <Button
                  type="submit"
                  form="request_demo2_form"
                  className={styles.button}
                  viewStyle="primary"
                  size="medium"
                  disabled={!isFormValid}
                  fullWidth
                >
                  {buttonText}
                </Button>
              </div>
              <div className={styles.notice}>
                <Trans
                  i18nKey="user_agreement_form_notice"
                  components={{ privacy: <Link href="/privacy" />, license: <Link href="/license" />, button: buttonText }}
                />
              </div>
            </>
          )}
          {isSuccess && (
            <>
              <div className={styles.resultTitle}>
                <Trans
                  i18nKey="you_signed_up_for_demonstration"
                  components={{ accent: <span className="accent-text" /> }}
                />
                {' '}<span className="accent-text">
                  {format(new Date(saveResult.date), 'dd.MM.yyyy')} {t('year_short')} {t('at')} {format(new Date(saveResult.date), 'HH:mm')}
                </span>
              </div>
              <div className={styles.resultSubtitle}>{t('demonstration_time_notice')}</div>
              {/* <div className={styles.resultSubtitle}>{t('demonstration_description')}</div> */}
            </>
          )}
          {isError && (
            <div className={styles.resultTitle}>{t('contacts_form_send_error')}</div>
          )}
        </div>
        {image}
      </div>
    </PageBlock>
  );
};

export default RequestDemo2;
